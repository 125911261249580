import React, { useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import VideoSlider from "../components/sliders/video-slider"
import VideoSliderSecond from "../components/sliders/video-slider-duplicate"
import BlogSlider from "../components/sliders/video-slider-three-col"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ClockIcon,
} from "@heroicons/react/24/solid"
import Uploader from "../components/drop-zone/uploader"
import { useForm } from "react-hook-form"
import firebase from "firebase/compat/app"
import db from "../firebaseConfig"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { map } from "jquery"
import CsrSlider from "../components/sliders/news-releated"
import { CopyToClipboard } from "react-copy-to-clipboard"
import playLeft from "../images/icons/playLeft.png"
import playRight from "../images/icons/playRight.png"

const News = ({ data }) => {
  const images = {
    blog1: getImage(data.blog1),
    blog2: getImage(data.blog2),
    blog3: getImage(data.blog3),
  }

  const [copied, setCopied] = useState(false)

  setTimeout(() => {
    setCopied(false)
  }, 3000)

  const newssimilar = [
    {
      topic: "Sharing the joys of Christmas with a friendly chat",
      date: "December 30, 2022",
      image2: images.blog3,
      id: "blog1",
      linkmore: "/sharing-the-joys-christmas-with-friendly-chat/",
    },
    {
      topic: "Watawala Tea Rings in Children’s Day with Fun-Filled Event ",
      date: "October 01, 2022",
      image2: images.blog1,
      id: "blog1",
      linkmore: "/watawala-tea-rings-in-children-day/",
    },
    {
      topic: "Inspiring Ethnic Harmony through Gratitude",
      date: "June 2019",
      image2: images.blog2,
      id: "blog2",
      linkmore: "/inspiring-ethnic-harmony-through-gratitude/",
    },

  ]

  //latest news section

  const latestnews = [
    {
      topic: "Sharing the joys of Christmas with a friendly chat",
      date: "December 30, 2022",
      id: "blog1",
      link: "/sharing-the-joys-christmas-with-friendly-chat/",
    },
    {
      topic: "දිරියට කෘතගුණ Children’s Day ‘22",
      date: "October 01, 2022",
      id: "blog1",
      link: "/childrens-day-22/",
    },
    {
      topic: "Watawala Tea Rings in Children’s Day with Fun-Filled Event ",
      date: "October 01, 2022",
      id: "blog1",
      link: "/watawala-tea-rings-in-children-day/",
    },
    {
      topic: "Inspiring Ethnic Harmony through Gratitude",
      date: "June 2019",
      id: "blog2",
      link: "/inspiring-ethnic-harmony-through-gratitude/",
    },
    {
      topic: "Watawala Scholarship Programme 2020",
      date: "May 2020",

      id: "blog3",
      link: "/watawala-scholarship-programme-2020/",
    },
    {
      topic: "Drinking Water Project – Kataragama",
      date: "September 11,2022",

      id: "blog4",
      link: "/drinking-water-project–kataragama/",
    },
    {
      topic: "Watawala Tea Joins the Fight Against COVID-19",
      date: "September 11,2022",
      id: "blog5",
      link: "/watawala-tea-joins-the-fight-against-covid-19/",
    },
  ]

  const mostpopular = [
    {
      topic: "Sharing the joys of Christmas with a friendly chat",
      date: "December 30, 2022",
      id: "blog1",
      link: "/sharing-the-joys-christmas-with-friendly-chat/",
    },
    {
      topic: "දිරියට කෘතගුණ Children’s Day ‘22",
      date: "October 01, 2022",
      id: "blog1",
      link: "/childrens-day-22/",
    },
    {
      topic: "Watawala Tea Joins the Fight Against COVID-19",
      date: "September 11,2022",
      id: "blog5",
      link: "/watawala-tea-joins-the-fight-against-covid-19/",
    },
    {
      topic: "Celebrating Avurudu with the Farming Community",
      date: "April 2019",
      id: "blog6",
      link: "/celebrating-avurudu-with-the-farming-community/",
    },
  ]

  const [readMore, setReadMore] = useState(true)

  return (
    <Layout>
      <section>
        <div className="container mx-auto md:px-20 px-10  pt-40 pb-20  ">
          <div className="flex lg:flex-row flex-col gap-7 ">
            <div className="w-full lg:w-3/4">
              <div className="pb-10 ">
                <div className="flex gap-3 text-black items-center pb-4 ">
                  <div>
                    <ClockIcon className="w-6 " />
                  </div>
                  <div> April 2019</div>
                </div>

                <div className="text-5xl text-black font-semibold  pt-2">
                  Celebrating Avurudu with the Farming Community
                </div>
              </div>

              <div className="pb-8">
                <StaticImage
                  src="../images/news/awurudu-full.png"
                  alt="Watawala"
                  className="w-full  rounded-3xl"
                />
              </div>

              <div className="text-base		font-light    leading-7		  text-justify	  sm:text-left		mb-8">
                Since ancient times the farming community has been the
                foundation that nourishes Sri Lanka. And yet, they have received
                little reward or appreciation for their efforts. Motivated by
                this fact, Watawala Tea embarked upon a project to help 500
                underprivileged farming families to celebrate Avurudu. The
                festivities included 15 different events that were organised and
                conducted by the team at Watawala Tea.
              </div>

              <div className="text-base		font-light    leading-7		  text-justify	  sm:text-left	   ">
                Furthermore, Watawala Tea organised an Avurudu celebration for
                Army officers who have been permanently disabled in the line of
                duty.
              </div>

              <div className="border-y-2   border-black		 py-6  	mt-20">
                <div className="flex gap-6  items-center ">
                  <div className="mt-2 sm:block hidden">
                    <StaticImage
                      src="../images/icons/share-social/share-icon.png"
                      className=""
                      alt="share"
                    />
                  </div>
                  <div className="">Share: </div>
                  <div>
                    <div className="flex gap-5">
                      <a
                        name="fb_share"
                        type="button_count"
                        href="http://www.facebook.com/sharer.php?text=watawalatea.lk/celebrating-avurudu-with-the-farming-community/"
                        target="_blank"
                        className="flex items-center"
                      >
                        <StaticImage
                          src="../images/icons/share-social/facebook.png"
                          className="sm:w-10 w-5"
                          alt="Watawala"
                        />
                      </a>
                      <a
                        href="https://twitter.com/intent/tweet?text=watawalatea.lk/celebrating-avurudu-with-the-farming-community/"
                        target="_blank"
                        className="flex items-center"
                      >
                        <StaticImage
                          src="../images/icons/share-social/twiter.png"
                          className="sm:w-10 w-5"
                          alt="Watawala"
                        />
                      </a>

                      <a
                        href="whatsapp://send?text=watawalatea.lk/celebrating-avurudu-with-the-farming-community/"
                        data-action="share/whatsapp/share"
                        className="flex items-center"
                      >
                        <StaticImage
                          src="../images/icons/share-social/whatsapp.png"
                          className="sm:w-10 w-5"
                          alt="Watawala"
                        />
                      </a>

                      <CopyToClipboard
                        text="watawalatea.lk/celebrating-avurudu-with-the-farming-community/"
                        onCopy={() => setCopied({ copied: true })}
                      >
                        <div className="flex items-center">
                          <StaticImage
                            src="../images/icons/share-social/share-link.png"
                            className="sm:w-10 w-5 cursor-pointer"
                            alt="Watawala"
                          />
                        </div>
                      </CopyToClipboard>
                      {copied && (
                        <div className="bg-yellow/50 rounded-xl py-2 text-sm px-5  bottom-0 z-10">
                          Copied
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-14">
                <div className="flex items-center justify-between">
                  <Link
                    to="/watawala-tea-joins-the-fight-against-covid-19"
                    className=""
                  >
                    <div className="flex items-center gap-3">
                      <div>
                        <img src={playLeft} width={60} />
                      </div>
                      <div className="flex flex-col">
                        <div className="uppercase font-light sm:block hidden ">
                          previous post
                        </div>
                        <div className="w-72 font-semibold xl:block hidden">
                          Watawala Tea Joins the Fight Against COVID-19
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/sharing-the-joys-christmas-with-friendly-chat/" className="">
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <div className="uppercase font-light sm:block hidden">
                          next post
                        </div>
                        <div className="w-72 font-semibold xl:block hidden">
                          Sharing the joys of Christmas with a friendly chat
                        </div>
                      </div>
                      <div>
                        <img src={playRight} width={60} />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="mt-20">
                <div className="text-xl font font-semibold ">
                  {" "}
                  SIMILAR POSTS
                </div>

                <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-8 gap-y-8 pt-8">
                  {newssimilar.map((item, index) => {
                    return (
                      <div className="" key={index} id={item.id}>
                        <div>
                          <a href={item.linkmore}>
                            <GatsbyImage
                              image={item.image2}
                              alt="Watawala"
                              className="w-full  h-60 rounded-3xl"
                            />{" "}
                          </a>

                          <div className="sm:px-6 md:px-1">
                            <div className="flex gap-3 text-black items-center pt-2 text-sm mt-4 mb-1">
                              <div>
                                <ClockIcon className="w-6 " />
                              </div>
                              <div>{item.date}</div>
                            </div>

                            <div className="text-2xl  text-black  font-medium">
                              <a href={item.linkmore}> {item.topic} </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4">
              <div className="bg-gray-100 px-6 py-8 rounded-2xl w-full mb-10 items-center">
                <div className="text-xl font font-semibold ">
                  THE LATEST NEWS
                </div>
                {latestnews.map((item, index) => {
                  return (
                    <div key={index}>
                      <div>
                        <div className="items-center pt-8">
                          <Link to={item.link}>
                            <div className="text-sm	 font-light	">
                              {item.date}
                            </div>
                            <div className="font-semibold">{item.topic}</div>
                          </Link>
                        </div>
                        <div className="bg-gray-500/20 h-[1px] w-full mt-8"></div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="bg-gray-100 px-6 py-8 rounded-2xl w-full mb-10 items-center">
                <div className="text-xl font font-semibold ">
                  MOST POPULAR NEWS
                </div>
                {mostpopular.map((item, index) => {
                  return (
                    <div key={index}>
                      <div>
                        <div className="items-center pt-8">
                          <Link to={item.link}>
                            <div className="text-sm	 font-light	">
                              {item.date}
                            </div>
                            <div className="font-semibold">{item.topic}</div>
                          </Link>
                        </div>
                        <div className="bg-gray-500/20 h-[1px] w-full mt-8"></div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="bg-gray-100 px-4 py-8 rounded-2xl mt-8">
                <div className="text-xl font font-semibold ">FOLLOW US</div>
                <div>
                  <div className="flex gap-5 mt-8">
                    <a
                      href="https://www.facebook.com/WatawalaTeaSriLanka/"
                      target="_blank"
                      className="group"
                    >
                      <div className="group-hover:hidden block">
                        <StaticImage
                          src="../images/icons/social-black/facebook.png"
                          className="w-10"
                          alt="Watawala"
                        />
                      </div>
                      <div className="group-hover:block hidden">
                        <StaticImage
                          src="../images/icons/social/facebookYellow.png"
                          className="w-10"
                          alt="Watawala"
                        />
                      </div>
                    </a>
                    <a
                      href="https://www.instagram.com/watawalatea/"
                      target="_blank"
                      className="group"
                    >
                      <div className="group-hover:hidden block">
                        <StaticImage
                          src="../images/icons/social-black/insta.png"
                          className="w-10"
                          alt="Watawala"
                        />
                      </div>
                      <div className="group-hover:block hidden">
                        <StaticImage
                          src="../images/icons/social/instagramYellow.png"
                          className="w-10"
                          alt="Watawala"
                        />
                      </div>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCYWRbGloP_sXJ_BsiDqY00w"
                      target="_blank"
                      className="group"
                    >
                      <div className="group-hover:hidden block">
                        <StaticImage
                          src="../images/icons/social-black/youtube.png"
                          className="w-10"
                          alt="Watawala"
                        />
                      </div>
                      <div className="group-hover:block hidden">
                        <StaticImage
                          src="../images/icons/social/youtubeYellow.png"
                          className="w-10"
                          alt="Watawala"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default News

export const query = graphql`
  query BlogpageImages {
    blog1: file(relativePath: { eq: "news/newnews-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blog2: file(relativePath: { eq: "news/news02.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blog3: file(relativePath: { eq: "news/news04/watawala-christmas-thumbnail.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
